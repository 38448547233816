'use client';

import { LoaderIcon } from 'lucide-react';
import { useEffect } from 'react';

import clientLogger from '@/lib/logger/client-logger';

import generatePath from '@/lib/router/generate-path';
import Routes from '@/lib/router/routes';

import { useRouter } from '@/lib/translation/hooks';

import { postSocialAuthCode } from './action';

const ClientPage = ({
  scope,
  code,
  callbackUrl,
}: {
  scope: string;
  code: string;
  callbackUrl: string;
}) => {
  const router = useRouter();
  useEffect(() => {
    postSocialAuthCode({
      key: scope,
      callbackUrl,
      code,
    })
      .then((res) => {
        if (res.status === 'error' && res.errors) {
          const searchParams = new URLSearchParams();
          searchParams.append('error', res.errors.root);
          router.replace(`${generatePath(Routes.SignIn)}?${searchParams}`);
        } else {
          router.replace(Routes.Home);
        }
      })
      .catch((error) => {
        clientLogger.error(error);
        // Warn the user
      });
    // Here we need to trigger the useEffect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen justify-center py-44">
      <LoaderIcon className="size-24 animate-spin" />
    </div>
  );
};

export default ClientPage;
